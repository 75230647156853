import { createBrowserHistory } from 'history';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import Web_CV from './pages/Web_CV';
import AdminAuth from './pages/AdminAuth';
import AdminSettings from './pages/AdminSettings';
import AdminDashboard from './pages/AdminDashboard';
import AdminMails from './pages/AdminMails';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/App.css';

const history = createBrowserHistory();

function App() {
  let style = {
    width: "100%",
    minHeight : "100vh",
    position: "absolute",
    top : "0px"
  };
  
  return (
    <div className="App" style={style} >
      <Router history={history}>
        <Switch >
          <Route exact path="/">
            <Redirect to="/web_cv" />
          </Route>
          <Route exact path="/web_cv/" component={Web_CV} />
          <Route exact path="/web_cv/admin/auth" component={AdminAuth} />
          <Route exact path="/web_cv/admin/dashboard" component={AdminDashboard} />
          <Route exact path="/web_cv/admin/settings" component={AdminSettings} />
          <Route exact path="/web_cv/admin/mails" component={AdminMails} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
