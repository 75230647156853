import { Container, Row, Col } from 'react-bootstrap';

function Footer(props) {
    let footerStyle = {
        position: "absolute",
        bottom: 0,
        paddingTop: "10px",
        paddingBottom: "10px",
    };

    let year = new Date().getFullYear();

    return (
        <Container fluid className="bgColor4" style={footerStyle}>
            <Row className="row align-items-end">
                <Col>
                    <p className="text-center m-0" style={{ fontSize: 10 }}>Copyright © 2021{(year !== 2021 ? (" - " + year) : "")}</p>
                </Col>
            </Row>
        </Container>
    );
}

export default Footer;