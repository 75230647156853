import { Fragment } from 'react';
import { Table, FormControl, Button } from 'react-bootstrap';

const InputsGrid = (props) => {
    // console.log("InputsGrid", props);

    if (!props.definition) return ('');

    var values = props.value ?? [];

    const setValues = (v) => {
        // console.log("setValues", v)
        props.onChange(v);
    }

    const handleDuplicate = (index) => {
        // console.log("handleDuplicate", index);
        // console.log(values);
        let tmp = values;
        tmp.splice((index + 1), 0, {...tmp[index]});
        setValues([...tmp]);
    };

    const handleDelete = (index) => {
        // console.log("handleDelete", index);
        let tmp = values;
        tmp.splice(index, 1);
        setValues([...tmp]);
    };

    const handleAddRow = () => {
        // console.log("handleAddRow");
        let tmp = values;
        let arr = {};
        props.definition.inputs.map((input, iInput) => {
            if (input.type === "inputsGrid") arr[input.id] = [];
            else arr[input.id] = "";
            return null;
        });
        tmp.push(arr);
        setValues([...tmp]);
    };

    const setInputValue = (iRow, iInput, e) => {
        // console.log("setInputValue", iRow, iInput, e);
        let tmp = values;
        props.definition.inputs.map((input, i) => {
            if(i === iInput) tmp[iRow][input.id] = e.target ? e.target.value : e;
            return null;
        });
        setValues([...tmp]);
    };

    return (
        <>
            <Table striped bordered hover size="sm" responsive variant="light">
                <thead >
                    <tr>
                        {
                            props.definition.inputs &&
                            props.definition.inputs.map((input, iInput) => {
                                if (input.type !== "inputsGrid") {
                                    return (
                                        <th key={`header${iInput}`} style={{ fontWeight: "bold" }}>{input.name}</th>
                                    )
                                }else{
                                    return(null);
                                }
                            })
                        }
                        {
                            (props.definition.duplicate || props.definition.delete) &&
                            <th style={{ fontWeight: "normal", }}></th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        values.map((row, iRow) => {
                            return (
                                <Fragment key={iRow}>
                                    <tr key={`row${iRow}`}>
                                        {
                                            props.definition.inputs &&
                                            props.definition.inputs.map((input, iInput) => {
                                                if (input.type !== "inputsGrid") {
                                                    return(
                                                        <td key={`data${iInput}`}>
                                                            <FormControl
                                                                {...(input.type === "textarea" ? { as: "textarea" } : { type: input.type })}
                                                                maxLength={input.maxlength ?? null}
                                                                rows={input.rows ?? 1}
                                                                value={values[iRow][input.id] ?? null}
                                                                onChange={(e) => setInputValue(iRow, iInput, e)}
                                                            >
                                                            </FormControl>
                                                        </td>
                                                    );
                                                }else{
                                                    return(null);
                                                }
                                            })
                                        }
                                        {
                                            (props.definition.duplicate || props.definition.delete) &&
                                            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                {
                                                    props.definition.duplicate && <Button className="m-1 p-1" variant="secondary" onClick={() => handleDuplicate(iRow)}>Duplicate</Button>
                                                }
                                                {
                                                    props.definition.delete && <Button className="m-1 p-1" variant="danger" onClick={() => handleDelete(iRow)}>Delete</Button>
                                                }
                                            </td>
                                        }
                                    </tr>
                                    <tr>
                                        <td colSpan={props.definition.inputs.length + (!props.definition.duplicate && !props.definition.delete ? 0 : 1)} style={{ paddingLeft: "30px" }}>
                                            {
                                                props.definition.inputs &&
                                                props.definition.inputs.map((input, iInput) => {
                                                    if (input.type === "inputsGrid") {
                                                        return (
                                                            <InputsGrid
                                                                key={`data${iInput}`}
                                                                definition={input}
                                                                value={values[iRow][input.id] ?? null}
                                                                onChange={(e) => setInputValue(iRow, iInput, e)}
                                                            />
                                                        );
                                                    }else{
                                                        return(null);
                                                    }
                                                })
                                            }
                                        </td>
                                    </tr>
                                </Fragment>
                            )
                        })
                    }
                    <tr>
                        <td colSpan={props.definition.inputs.length + (!props.definition.duplicate && !props.definition.delete ? 0 : 1)}>
                            <Button className="m-1 p-1" variant="secondary" onClick={handleAddRow}>Add a row</Button>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </>
    );
}

export default InputsGrid;