import { useHistory } from 'react-router-dom';
// import dotenv from 'dotenv';
import { useState } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { GoogleLogin } from 'react-google-login';
import Header from './../components/Header';
import Footer from './../components/Footer';

function AdminAuth() {
    const history = useHistory();
    // dotenv.config();
    const clientId = process.env.REACT_APP_OAUTH_ID;

    let headerHeight = "90px";

    let pageStyle = {
        minHeight: "100vh",
        width: "100%",
    }
    let cardStyle = {
        minHeight: "200px",
    }
    let titleCardStyle = {
        textAlign: "center",
        margin: "0px",
        padding: "20px",
    };

    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState("");

    const onFailure = (res) => {
        console.log("Login onFailure, res:", res);
        setModalTitle("Erreur");
        setModalContent("Une erreur est survenue");
        setModalShow(true);
    }

    const onSuccess = (res) => {
        // console.log("Login onSuccess, res:", res);

        let params = {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ token: res.getAuthResponse().id_token })
        }

        fetch("/api/web_cv/admin/auth/", params).then((res) => {
            res.json().then((data) => {
                // console.log(data);

                if (data.status) {
                    history.push("/web_cv/admin/dashboard");
                } else {
                    setModalTitle("Erreur");
                    setModalContent((data.message ?? "Une erreur est survenue"));
                    setModalShow(true);
                }
            }).catch((err) => {
                console.error("Une erreur est survenue. ERR:", err);
                setModalTitle("Erreur");
                setModalContent("Une erreur est survenue.");
                setModalShow(true);
            });
        });
    }

    return (
        <div className="bgColor1" style={pageStyle}>
            <Header height={headerHeight} title="Web CV - Admin" />
            <Container fluid="md">
                <Row style={{ paddingTop: "100px", paddingBottom: "100px" }}>
                    <Col className="text-center bgColor2" style={cardStyle}>
                        <Row>
                            <h3 className="bgColor3" style={titleCardStyle}>
                            Authentification
                            </h3>
                        </Row>

                        <GoogleLogin
                            className="m-3"
                            clientId={clientId}
                            buttonText="Se connecter avec Google"
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            cookiePolicy={"single_host_origin"}
                            style={{}}
                            isSignedIn={false}
                        />
                    </Col>
                </Row>
            </Container>
            <Footer />
            
            <>
                <Modal show={modalShow} onHide={() => setModalShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ whiteSpace: "pre" }}>{modalContent}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setModalShow(false)}>
                            Fermer
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        </div>
    );
}

export default AdminAuth;