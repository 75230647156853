import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Modal, Button, Nav, Offcanvas } from 'react-bootstrap';
import Header from '../components/Header';
import Footer from '../components/Footer';

function AdminConfiguration() {
    const history = useHistory();

    let headerHeight = "90px";

    let pageStyle = {
        minHeight: "100vh",
        width: "100%",
    }
    let cardStyle = {
        minHeight: "200px",
    }
    let titleCardStyle = {
        textAlign: "center",
        margin: "0px",
        padding: "20px",
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [monthlyVisits, setMonthlyVisits] = useState(undefined);
    const [weeklyVisits, setWeeklyVisits] = useState(undefined);
    const [allVisits, setAllVisits] = useState(undefined);
    const [monthlyMails, setMonthlyMails] = useState(undefined);
    const [weeklyMails, setWeeklyMails] = useState(undefined);
    const [allMails, setAllMails] = useState(undefined);

    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState("");

    useEffect(() => {
        // console.log("useEffect");

        fetch("/api/web_cv/admin/dashboard/").then((res) => {
            res.json().then((data) => {
                // console.log(data);

                if (data.status) {
                    setMonthlyVisits(data.dashboard.monthlyVisits);
                    setWeeklyVisits(data.dashboard.weeklyVisits);
                    setAllVisits(data.dashboard.allVisits);
                    setMonthlyMails(data.dashboard.monthlyMails);
                    setWeeklyMails(data.dashboard.weeklyMails);
                    setAllMails(data.dashboard.allMails);
                } else {
                    if (data.logged === false) {
                        history.push("/web_cv/admin/auth");
                        return;
                    }
                    setModalTitle("Erreur");
                    setModalContent((data.message ?? "Une erreur est survenue"));
                    setModalShow(true);
                }
            }).catch((err) => {
                console.error("Une erreur est survenue. ERR:", err);
                setModalTitle("Erreur");
                setModalContent("Une erreur est survenue.");
                setModalShow(true);
            });
        });
    }, [history]);


    return (
        <div className="bgColor1" style={pageStyle}>
            <Header height={headerHeight} title="Web CV - Admin" />
            <>
                <div style={{ position: "fixed", top: "0px", left: "20px", height: headerHeight }}>
                    <Row className="h-100">
                        <Col className="my-auto text-center">
                            <Button variant="primary" onClick={handleShow}>
                                Menu
                            </Button>
                        </Col>
                    </Row>
                </div>

                <Offcanvas className="bgColor2" style={{ maxWidth: "200px" }} restoreFocus={false} scroll={true} backdrop={true} show={show} onHide={handleClose} >
                    <Offcanvas.Header className="bgColor4" style={{ height: headerHeight }} closeButton>
                        <Offcanvas.Title>Menu</Offcanvas.Title>

                    </Offcanvas.Header>
                    <Offcanvas.Body className="p-0">
                        <Nav className="flex-column p-2" variant="pills" defaultActiveKey="/web_cv/admin/dashboard">
                            <Nav.Link href="/web_cv/admin/dashboard" onClick={() => setShow(false)}>Dashboard</Nav.Link>
                            <Nav.Link href="/web_cv/admin/settings" onClick={() => setShow(false)}>Settings</Nav.Link>
                            <Nav.Link href="/web_cv/admin/mails" onClick={() => setShow(false)}>Mails</Nav.Link>
                            <Nav.Link href="/" onClick={() => setShow(false)}>Web CV</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
            <Container fluid="md">
                <Row style={{ paddingTop: "100px", paddingBottom: "100px" }}>
                    <Col className="bgColor2" style={cardStyle} md={12}>
                        <Row>
                            <h3 className="bgColor3" style={titleCardStyle}>
                                Dashboard
                            </h3>
                        </Row>

                        <Row className="mt-3 mb-3">
                            <Col xl={6}>
                                <p>Nombre de visite cette semaine : {weeklyVisits}</p>
                                <p>Nombre de visite ce mois : {monthlyVisits}</p>
                                <p>Nombre de visite totale : {allVisits}</p>
                            </Col>
                            <Col xl={6}>
                                <p>Nombre de mails cette semaine : {weeklyMails}</p>
                                <p>Nombre de mails ce mois : {monthlyMails}</p>
                                <p>Nombre de mails totale : {allMails}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Footer />
            <>
                <Modal show={modalShow} onHide={() => setModalShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ whiteSpace: "pre" }}>{modalContent}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setModalShow(false)}>
                            Fermer
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        </div>
    );
}

export default AdminConfiguration;