import { useRef, useState, useEffect, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  FormControl,
  ProgressBar,
} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import Header from "./../components/Header";
import Footer from "./../components/Footer";

import imgIdentity from "./../images/identity.jpg";
import imgCompany from "./../images/company.jpg";
import imgSkills from "./../images/skills.jpg";
import imgSettings from "./../images/settings.png";

function Web_CV() {
  let headerHeight = "150px";

  let pageStyle = {
    height: "100%",
    width: "100%",
  };

  let cardStyle = {
    minHeight: "200px",
  };
  let titleCardStyle = {
    textAlign: "justify",
    margin: "0px",
    padding: "20px",
  };
  let contentCardStyle = {
    textAlign: "justify",
    padding: "20px",
  };

  const recaptchaRef = useRef();

  const [information, setInformation] = useState("{information}");
  const [fullname, setFullname] = useState("{fullname}");
  const [profession, setProfession] = useState("{profession}");
  const [age, setAge] = useState("{age}");
  const [status, setStatus] = useState("{status}");
  const [aboutMe, setAboutMe] = useState("{aboutMe}");
  const [hobbies, setHobbies] = useState("{hobbies}");
  const [languages, setLanguages] = useState("{languages}");
  const [jobs, setJobs] = useState("{jobs}");
  const [skills, setSkills] = useState("{skills}");
  const [softwares, setSoftwares] = useState("{softwares}");

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(undefined);
  const [subject, setSubject] = useState("");
  const [validSubject, setValidSubject] = useState(undefined);
  const [body, setBody] = useState("");
  const [validBody, setValidBody] = useState(undefined);
  const [reCaptchaToken, setreCaptchaToken] = useState("");

  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");

  const resetStyleContactForm = () => {
    setValidEmail(null);
    setValidSubject(null);
    setValidBody(null);
  };

  const resetDataContactForm = () => {
    setEmail("");
    setSubject("");
    setBody("");
    recaptchaRef.current.reset();
  };

  const handleClickSendMail = () => {
    let re = /\S+@\S+\.\S+/;
    let errors = [];

    if (email.length === 0 || email.length > 50 || re.test(email) === false) {
      errors.push("- Email non valide.");
      setValidEmail(false);
    }

    if (subject.length === 0 || subject.length > 100) {
      errors.push("- Objet non valide.");
      setValidSubject(false);
    }

    if (body.length === 0 || body.length > 1000) {
      errors.push("- Corps non valide.");
      setValidBody(false);
    }

    if (reCaptchaToken.length === 0) {
      errors.push("- reCAPTCHA non valide.");
    }

    if (errors.length > 0) {
      setModalTitle("Erreur");
      setModalContent(errors.join("\n"));
      setModalShow(true);
      return;
    }

    let params = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        subject: subject,
        body: body,
        timestampIsoString: new Date().toISOString() ?? false,
        ReCAPTCHA: reCaptchaToken,
      }),
    };

    fetch("/api/web_cv/sendMail/", params).then((res) => {
      res
        .json()
        .then((data) => {
          let modalTitle = "";

          if (data.status) {
            modalTitle = "Information";
            resetDataContactForm();
          } else {
            modalTitle = "Erreur";
          }

          resetStyleContactForm();
          setModalTitle(modalTitle);
          setModalContent(data.message ?? "Une erreur est survenue");
          setModalShow(true);
        })
        .catch((err) => {
          console.error("Une erreur est survenue. ERR:", err);
          setModalTitle("Erreur");
          setModalContent("Une erreur est survenue.");
          setModalShow(true);
        });
    });
  };

  useEffect(() => {
    // console.log("useEffect");

    let params = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        timestampIsoString: new Date().toISOString() ?? false,
      }),
    };

    fetch("/api/web_cv/print/", params).then((res) => {
      res
        .json()
        .then((data) => {
          if (data.status) {
            let dateNow = new Date();
            let dateBirthDay = new Date(data.settings.general.birthdate);
            dateBirthDay.setYear(dateNow.getFullYear());

            if (data.settings.general.information)
              setInformation(data.settings.general.information);
            if (data.settings.general.fullname)
              setFullname(data.settings.general.fullname);
            if (data.settings.general.profession)
              setProfession(data.settings.general.profession);
            if (data.settings.general.birthdate)
              setAge(
                new Date().getFullYear() -
                  new Date(data.settings.general.birthdate).getFullYear() +
                  (dateNow < dateBirthDay ? -1 : 0)
              );
            if (data.settings.general.status)
              setStatus(data.settings.general.status);
            if (data.settings.general.about_me)
              setAboutMe(data.settings.general.about_me);
            if (data.settings.general.hobbies)
              setHobbies(data.settings.general.hobbies);

            if (data.settings.languages)
              setLanguages(data.settings.languages ?? null);
            if (data.settings.jobs) setJobs(data.settings.jobs ?? null);
            if (data.settings.skills) setSkills(data.settings.skills ?? null);
            if (data.settings.softwares)
              setSoftwares(data.settings.softwares ?? null);
          } else {
            setModalTitle("Erreur");
            setModalContent(data.message ?? "Une erreur est survenue");
            setModalShow(true);
          }
        })
        .catch((err) => {
          console.error("Une erreur est survenue. ERR:", err);
          setModalTitle("Erreur");
          setModalContent("Une erreur est survenue.");
          setModalShow(true);
        });
    });
  }, []);
  // console.log(languages);
  return (
    <div className="bgColor1" style={pageStyle}>
      <Header
        height={headerHeight}
        title={fullname}
        subtitle={`${profession} - ${age} ans<br/>${status}`}
      />

      <Container fluid="md">
        <Row style={{ paddingTop: "75px" }}>
          <Col
            className="bgColor2 mt-lg-0 mt-3"
            style={cardStyle}
            lg={12}
            xs={{ order: "last" }}
          >
            <Row>
              <h3 className="bgColor3" style={titleCardStyle}>
                Information
              </h3>
            </Row>
            <Row>
              <span
                style={contentCardStyle}
                dangerouslySetInnerHTML={{
                  __html: information,
                }}
              ></span>
            </Row>
          </Col>
        </Row>

        <Row style={{ paddingTop: "75px" }}>
          <Col
            className="bgColor2 mt-lg-0 mt-3"
            style={cardStyle}
            lg={{ order: "last", span: 6 }}
            xs={{ order: "last" }}
          >
            <Row>
              <h3 className="bgColor3" style={titleCardStyle}>
                À propos de moi
              </h3>
            </Row>
            <Row>
              <span
                style={contentCardStyle}
                dangerouslySetInnerHTML={{
                  __html: aboutMe,
                }}
              ></span>
            </Row>
          </Col>
          <Col
            className="m-auto text-center"
            lg={{ order: "first", span: 6 }}
            xs={{ order: "first" }}
          >
            <img
              className=""
              alt="identity"
              src={imgIdentity}
              style={{ maxWidth: "100%", maxHeight: "250px" }}
            />
          </Col>
        </Row>
        <Row style={{ paddingTop: "75px" }}>
          <Col
            className="m-auto text-center"
            lg={{ order: "last", span: 6 }}
            xs={{ order: "first" }}
          >
            <img
              className=""
              alt="company"
              src={imgCompany}
              style={{ maxWidth: "100%", maxHeight: "250px" }}
            />
          </Col>
          <Col
            className="bgColor2 mt-lg-0 mt-3"
            style={cardStyle}
            lg={{ order: "first", span: 6 }}
            xs={{ order: "last" }}
          >
            <Row>
              <h3 className="bgColor3" style={titleCardStyle}>
                Expériences professionnelles
              </h3>
            </Row>
            <Row>
              {!Array.isArray(jobs) && (
                <span style={contentCardStyle}>{jobs}</span>
              )}
              {Array.isArray(jobs) &&
                jobs.map((job, iJob) => {
                  let start =
                    new Intl.DateTimeFormat("fr-FR", { month: "long" }).format(
                      new Date(job.start)
                    ) +
                    " " +
                    new Date(job.start).getFullYear();
                  start = start[0].toUpperCase() + start.slice(1);

                  let end = job.end ?? "";
                  end =
                    end.length > 0
                      ? new Intl.DateTimeFormat("fr-FR", {
                          month: "long",
                        }).format(new Date(end)) +
                        " " +
                        new Date(end).getFullYear()
                      : "";
                  if (end.length > 0) end = end[0].toUpperCase() + end.slice(1);

                  let ul = null;

                  if (
                    Array.isArray(job.professional_experiences) &&
                    job.professional_experiences.length > 0
                  ) {
                    let li = job.professional_experiences.map(
                      (professional_experience, iProfessional_experience) => {
                        return (
                          <li key={iProfessional_experience}>
                            {professional_experience.experience}
                          </li>
                        );
                      }
                    );

                    ul = <ul>{li}</ul>;
                  }

                  return (
                    <span key={iJob} style={contentCardStyle}>
                      <b>{job.profession}</b> chez <b>{job.company}</b> à{" "}
                      {job.location} / Depuis {start}
                      {end.length > 0 ? " à " + end : ""}
                      {ul}
                    </span>
                  );
                })}
            </Row>
          </Col>
        </Row>
        <Row style={{ paddingTop: "75px" }}>
          <Col
            className="bgColor2 mt-lg-0 mt-3"
            style={cardStyle}
            lg={{ order: "last", span: 6 }}
            xs={{ order: "last" }}
          >
            <Row>
              <h3 className="bgColor3" style={titleCardStyle}>
                Compétences
              </h3>
            </Row>
            <Row
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingBottom: "20px",
              }}
            >
              {Array.isArray(skills) &&
                skills.map((skill, iSkill) => {
                  return (
                    <span
                      key={iSkill}
                      style={{ padding: "0px", paddingTop: "20px" }}
                    >
                      <p style={{ margin: "0px" }}>
                        <b>{skill.name}</b>
                      </p>
                      <ProgressBar
                        now={skill.percent}
                        label={`${skill.percent}%`}
                        visuallyHidden
                      />
                      <p
                        style={{
                          margin: "0px",
                          paddingLeft: "5px",
                          fontSize: "0.8rem",
                        }}
                      >
                        {skill.comment.length > 0 ? "* " + skill.comment : ""}
                      </p>
                    </span>
                  );
                })}
            </Row>
          </Col>
          <Col
            className="m-auto text-center"
            lg={{ order: "first", span: 6 }}
            xs={{ order: "first" }}
          >
            <img
              className=""
              alt="skills"
              src={imgSkills}
              style={{ maxWidth: "100%", maxHeight: "250px" }}
            />
          </Col>
        </Row>
        <Row style={{ paddingTop: "75px" }}>
          <Col
            className="m-auto text-center"
            lg={{ order: "last", span: 6 }}
            xs={{ order: "first" }}
          >
            <img
              className=""
              alt="engine"
              src={imgSettings}
              style={{ maxWidth: "100%", maxHeight: "250px" }}
            />
          </Col>
          <Col
            className="bgColor2 mt-lg-0 mt-3"
            style={cardStyle}
            lg={{ order: "first", span: 6 }}
            xs={{ order: "last" }}
          >
            <Row>
              <h3 className="bgColor3" style={titleCardStyle}>
                Logiciels
              </h3>
            </Row>
            <Row
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingBottom: "20px",
              }}
            >
              {Array.isArray(softwares) &&
                softwares.map((logiciel, iLogiciel) => {
                  return (
                    <span
                      key={iLogiciel}
                      style={{ padding: "0px", paddingTop: "20px" }}
                    >
                      <p style={{ margin: "0px" }}>
                        <b>{logiciel.name}</b>
                      </p>
                      <ProgressBar
                        now={logiciel.percent}
                        label={`${logiciel.percent}%`}
                        visuallyHidden
                      />
                      <p
                        style={{
                          margin: "0px",
                          paddingLeft: "5px",
                          fontSize: "0.8rem",
                        }}
                      >
                        {logiciel.comment.length > 0
                          ? "* " + logiciel.comment
                          : ""}
                      </p>
                    </span>
                  );
                })}
            </Row>
          </Col>
        </Row>

        <Row style={{ paddingTop: "75px" }}>
          <Col
            className="bgColor2 mt-lg-0 mt-3"
            style={cardStyle}
            lg={12}
            xs={{ order: "last" }}
          >
            <Row>
              <h3 className="bgColor3" style={titleCardStyle}>
                Hobbies
              </h3>
            </Row>
            <Row>
              <span
                style={contentCardStyle}
                dangerouslySetInnerHTML={{
                  __html: hobbies,
                }}
              ></span>
            </Row>
          </Col>
        </Row>

        <Row style={{ paddingTop: "75px", paddingBottom: "100px" }}>
          <Col className="bgColor2" style={cardStyle}>
            <Row>
              <h3
                className="bgColor3"
                style={{ ...titleCardStyle, textAlign: "center" }}
              >
                Me contacter
              </h3>
            </Row>
            <Row style={contentCardStyle}>
              <Row>
                <Col>
                  <label>
                    <span style={{ color: "red" }}>* </span>Email :
                  </label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormControl
                    type="email"
                    maxLength={100}
                    className={
                      validEmail === true
                        ? "is-valid"
                        : validEmail === false
                        ? "is-invalid"
                        : ""
                    }
                    id="inputEmail"
                    aria-describedby="emailHelp"
                    placeholder="Votre adresse mail"
                    onChange={(e) => {
                      setValidEmail(null);
                      setEmail(e.target.value);
                    }}
                    value={email}
                  />
                  <small id="emailHelp" className="form-text text-muted">
                    ({100 - email.length} caractères restant)
                  </small>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <label>
                    <span style={{ color: "red" }}>* </span>Objet :
                  </label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormControl
                    type="text"
                    maxLength={100}
                    className={
                      validSubject === true
                        ? "is-valid"
                        : validSubject === false
                        ? "is-invalid"
                        : ""
                    }
                    id="inputSubject"
                    aria-describedby="subjectHelp"
                    placeholder="Sujet"
                    onChange={(e) => {
                      setValidSubject(null);
                      setSubject(e.target.value);
                    }}
                    value={subject}
                  />
                  <small id="subjectHelp" className="form-text text-muted">
                    ({100 - subject.length} caractères restant)
                  </small>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <label>
                    <span style={{ color: "red" }}>* </span>Corps :
                  </label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormControl
                    as="textarea"
                    rows={10}
                    maxLength={1000}
                    className={
                      validBody === true
                        ? "is-valid"
                        : validBody === false
                        ? "is-invalid"
                        : ""
                    }
                    id="textareaBody"
                    aria-describedby="bodyHelp"
                    placeholder="Votre message"
                    onChange={(e) => {
                      setValidBody(null);
                      setBody(e.target.value);
                    }}
                    value={body}
                  />
                  <small id="bodyHelp" className="form-text text-muted">
                    ({1000 - body.length} caractères restant)
                  </small>
                </Col>
              </Row>
              <Row className="mt-3">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LeZQ3kqAAAAAPU0yMssRcU-0AdrXwgFxSEPpQr_"
                  theme="light"
                  size="normal"
                  // badge="bottomright"
                  onChange={(value) => setreCaptchaToken(value)}
                />
              </Row>
              <Row className="mt-3">
                <Col>
                  <Button
                    type="submit"
                    className=""
                    onClick={handleClickSendMail}
                  >
                    Envoyer
                  </Button>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
      <>
        <Modal show={modalShow} onHide={() => setModalShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ whiteSpace: "pre" }}>{modalContent}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalShow(false)}>
              Fermer
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
}

export default Web_CV;
