import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Modal, Button, Nav, Offcanvas, FormControl, FormCheck } from 'react-bootstrap';
import Header from './../components/Header';
import Footer from './../components/Footer';
import InputsGrid from './../components/InputsGrid';

function AdminSettings(props) {
    // console.log("AdminSettings", props);
    const history = useHistory();

    let headerHeight = "90px";

    let pageStyle = {
        minHeight: "100vh",
        width: "100%",
    }
    let cardStyle = {
        minHeight: "200px",
    }
    let titleCardStyle = {
        textAlign: "center",
        margin: "0px",
        padding: "20px",
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [email, setEmail] = useState("");
    const [fullname, setFullname] = useState("");
    const [validFullname, setValidFullname] = useState(undefined);
    const [telephone, setTelephone] = useState("");
    const [validTelephone, setValidTelephone] = useState(undefined);
    const [profession, setProfession] = useState("");
    const [validProfession, setValidProfession] = useState(undefined);
    const [birthdate, setBirthdate] = useState("");
    const [validBirthdate, setValidBirthdate] = useState(undefined);
    const [information, setInformation] = useState("");
    const [validInformation, setValidInformation] = useState(undefined);
    const [aboutMe, setAboutMe] = useState("");
    const [validAboutMe, setValidAboutMe] = useState(undefined);
    const [hobbies, setHobbies] = useState("");
    const [validHobbies, setValidHobbies] = useState(undefined);
    const [status, setStatus] = useState("");
    const [validStatus, setValidStatus] = useState(undefined);
    const [driverLicense, setDriverLicense] = useState(undefined);
    const [vehicle, setVehicle] = useState(undefined);
    const [languages, setLanguages] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [skills, setSkills] = useState([]);
    const [softwares, setSoftwares] = useState([]);

    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState("");

    const handleSubmit = () => {
        //// console.log("handleSubmit");

        let params = {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                fullname: fullname,
                telephone: telephone,
                profession: profession,
                birthdate: birthdate,
                status: status,
                information: information,
                about_me: aboutMe,
                hobbies: hobbies,
                driver_license: driverLicense,
                vehicle: vehicle,
                languages: languages,
                jobs: jobs,
                skills: skills,
                professionnal_skills: skills,
                softwares: softwares,
            }),
        }

        fetch("/api/web_cv/admin/settings/", params).then((res) => {
            res.json().then((data) => {
                //// console.log(data);

                if (data.status) {
                    setModalTitle("Information");
                    setModalContent("Mise à jour effectuée.");
                    setModalShow(true);
                } else {
                    if (data.logged === false) {
                        history.push("/web_cv/admin/auth");
                        return;
                    }
                    setModalTitle("Erreur");
                    setModalContent((data.message ?? "Une erreur est survenue"));
                    setModalShow(true);
                }
            }).catch((err) => {
                //console.error("Une erreur est survenue. ERR:",err);
                setModalTitle("Erreur");
                setModalContent("Une erreur est survenue.");
                setModalShow(true);
            });
        });
    };

    useEffect(() => {
        //// console.log("useEffect");

        fetch("/api/web_cv/admin/settings/").then((res) => {
            res.json().then((data) => {
                // console.log(data);

                if (data.status) {
                    if (data.settings.general.email) setEmail(data.settings.general.email ?? "");
                    if (data.settings.general.fullname) setFullname(data.settings.general.fullname ?? "");
                    if (data.settings.general.telephone) setTelephone(data.settings.general.telephone ?? "");
                    if (data.settings.general.profession) setProfession(data.settings.general.profession ?? "");
                    if (data.settings.general.birthdate) {
                        let dt = new Date(data.settings.general.birthdate);
                        dt.setMinutes(new Date(data.settings.general.birthdate).getMinutes() - new Date(data.settings.general.birthdate).getTimezoneOffset());
                        dt = dt.toISOString().slice(0, 10);
                        setBirthdate(dt);
                    }
                    if (data.settings.general.status) setStatus(data.settings.general.status ?? "");
                    if (data.settings.general.information) setInformation(data.settings.general.information ?? "");
                    if (data.settings.general.about_me) setAboutMe(data.settings.general.about_me ?? "");
                    if (data.settings.general.hobbies) setHobbies(data.settings.general.hobbies ?? "");
                    if (data.settings.general.driver_license) setDriverLicense(data.settings.general.driver_license ?? null);
                    if (data.settings.general.vehicle) setVehicle(data.settings.general.vehicle ?? null);

                    if (data.settings.languages) setLanguages(data.settings.languages ?? null);
                    if (data.settings.jobs) setJobs(data.settings.jobs ?? null);
                    if (data.settings.skills) setSkills(data.settings.skills ?? null);
                    if (data.settings.softwares) setSoftwares(data.settings.softwares ?? null);

                } else {
                    if (data.logged === false) {
                        history.push("/web_cv/admin/auth");
                        return;
                    }
                    setModalTitle("Erreur");
                    setModalContent((data.message ?? "Une erreur est survenue"));
                    setModalShow(true);
                }
            }).catch((err) => {
                // console.error("Une erreur est survenue. ERR:", err);
                setModalTitle("Erreur");
                setModalContent("Une erreur est survenue.");
                setModalShow(true);
            });
        });
    }, [history]);

    return (
        <div className="bgColor1" style={pageStyle}>
            <Header height={headerHeight} title="Web CV - Admin" />
            <>
                <div style={{ position: "fixed", top: "0px", left: "20px", height: headerHeight }}>
                    <Row className="h-100">
                        <Col className="my-auto text-center">
                            <Button variant="primary" onClick={handleShow}>
                                Menu
                            </Button>
                        </Col>
                    </Row>
                </div>

                <Offcanvas className="bgColor2" style={{ maxWidth: "200px" }} restoreFocus={false} scroll={true} backdrop={true} show={show} onHide={handleClose} >
                    <Offcanvas.Header className="bgColor4" style={{ height: headerHeight }} closeButton>
                        <Offcanvas.Title>Menu</Offcanvas.Title>

                    </Offcanvas.Header>
                    <Offcanvas.Body className="p-0">
                        <Nav className="flex-column p-2" variant="pills" defaultActiveKey="/web_cv/admin/settings">
                            <Nav.Link href="/web_cv/admin/dashboard" onClick={() => setShow(false)}>Dashboard</Nav.Link>
                            <Nav.Link href="/web_cv/admin/settings" onClick={() => setShow(false)}>Settings</Nav.Link>
                            <Nav.Link href="/web_cv/admin/mails" onClick={() => setShow(false)}>Mails</Nav.Link>
                            <Nav.Link href="/" onClick={() => setShow(false)}>Web CV</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
            <Container fluid="md">
                <Row style={{ paddingTop: "100px", paddingBottom: "100px" }}>
                    <Col className="bgColor2" style={cardStyle} md={12}>
                        <Row>
                            <h3 className="bgColor3" style={titleCardStyle}>
                                Settings
                            </h3>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <label>
                                    <span style={{ color: "red" }}>* </span>Email :
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControl
                                    disabled
                                    type="email"
                                    maxLength={100}
                                    id="inputEmail"
                                    aria-describedby="emailHelp"
                                    value={email}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <label>
                                    <span style={{ color: "red" }}>* </span>Fullname :
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControl
                                    type="text"
                                    maxLength={100}
                                    className={(validFullname === true ? "is-valid" : (validFullname === false ? "is-invalid" : ""))}
                                    id="inputFullname"
                                    aria-describedby="fullnameHelp"
                                    placeholder="Fullname"
                                    onChange={e => { setValidFullname(null); setFullname(e.target.value) }}
                                    value={fullname}
                                />
                                <small id="fullnameHelp" className="form-text text-muted">({100 - fullname.length} caractères restant)</small>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <label>
                                    <span style={{ color: "red" }}>* </span>Telephone :
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControl
                                    type="text"
                                    maxLength={100}
                                    className={(validTelephone === true ? "is-valid" : (validTelephone === false ? "is-invalid" : ""))}
                                    id="inputTelephone"
                                    aria-describedby="telephoneHelp"
                                    placeholder="Telephone"
                                    onChange={e => { setValidTelephone(null); setTelephone(e.target.value) }}
                                    value={telephone}
                                />
                                <small id="telephoneHelp" className="form-text text-muted">({100 - telephone.length} caractères restant)</small>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <label>
                                    <span style={{ color: "red" }}>* </span>Profession :
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControl
                                    type="text"
                                    maxLength={100}
                                    className={(validProfession === true ? "is-valid" : (validProfession === false ? "is-invalid" : ""))}
                                    id="inputProfession"
                                    aria-describedby="professionHelp"
                                    placeholder="Profession"
                                    onChange={e => { setValidProfession(null); setProfession(e.target.value) }}
                                    value={profession}
                                />
                                <small id="professionHelp" className="form-text text-muted">({100 - profession.length} caractères restant)</small>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <label>
                                    <span style={{ color: "red" }}>* </span>Birthdate :
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControl
                                    type="date"
                                    maxLength={100}
                                    className={(validBirthdate === true ? "is-valid" : (validBirthdate === false ? "is-invalid" : ""))}
                                    id="inputBirthdate"
                                    aria-describedby="birthdateHelp"
                                    placeholder="Birthdate"
                                    onChange={e => { setValidBirthdate(null); setBirthdate(e.target.value) }}
                                    value={birthdate}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <label>
                                    <span style={{ color: "red" }}>* </span>Status :
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControl
                                    type="text"
                                    maxLength={100}
                                    className={(validStatus === true ? "is-valid" : (validStatus === false ? "is-invalid" : ""))}
                                    id="inputStatus"
                                    aria-describedby="statusHelp"
                                    placeholder="Status"
                                    onChange={e => { setValidStatus(null); setStatus(e.target.value) }}
                                    value={status}
                                />
                                <small id="statusHelp" className="form-text text-muted">({100 - status.length} caractères restant)</small>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <FormCheck
                                    type="checkbox"
                                    maxLength={100}
                                    className=""
                                    id="inputDriverLicense"
                                    onChange={e => { setDriverLicense(e.target.value) }}
                                    defaultChecked={driverLicense}
                                    label="Driver license"
                                />
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <FormCheck
                                    type="checkbox"
                                    maxLength={100}
                                    className=""
                                    id="inputVehicle"
                                    onChange={e => { setVehicle(e.target.value) }}
                                    defaultChecked={vehicle}
                                    label="Vehicle"
                                />
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <label>
                                    <span style={{ color: "red" }}>* </span>Information :
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControl
                                    as="textarea" rows={10}
                                    className={(validInformation === true ? "is-valid" : (validInformation === false ? "is-invalid" : ""))}
                                    id="inputInformation"
                                    aria-describedby="informationHelp"
                                    placeholder="About Me"
                                    onChange={e => { setValidInformation(null); setInformation(e.target.value) }}
                                    value={information}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <label>
                                    <span style={{ color: "red" }}>* </span>About Me :
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControl
                                    as="textarea" rows={10}
                                    className={(validAboutMe === true ? "is-valid" : (validAboutMe === false ? "is-invalid" : ""))}
                                    id="inputAboutMe"
                                    aria-describedby="aboutMeHelp"
                                    placeholder="About Me"
                                    onChange={e => { setValidAboutMe(null); setAboutMe(e.target.value) }}
                                    value={aboutMe}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <label>
                                    <span style={{ color: "red" }}>* </span>Hobbies :
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControl
                                    as="textarea" rows={10}
                                    className={(validHobbies === true ? "is-valid" : (validHobbies === false ? "is-invalid" : ""))}
                                    id="inputHobbies"
                                    aria-describedby="hobbiesHelp"
                                    placeholder="Hobbies"
                                    onChange={e => { setValidHobbies(null); setHobbies(e.target.value) }}
                                    value={hobbies}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <label>
                                    Languages :
                                </label>
                            </Col>
                        </Row>
                        <InputsGrid
                            definition={{
                                name: "languages",
                                inputs: [
                                    {
                                        id: "name",
                                        name: "Name",
                                        type: "text",
                                        maxlength: 100,
                                    },
                                    {
                                        id: "percent",
                                        name: "Percent",
                                        type: "number",
                                        maxlength: 2,
                                    },
                                    {
                                        id: "comment",
                                        name: "Comment",
                                        type: "textarea",
                                        maxlength: 100,
                                        rows: 3,
                                    },
                                ],
                                duplicate: true,
                                delete: true,
                            }
                            }
                            value={languages}
                            onChange={setLanguages}
                        />

                        <Row className="mt-3">
                            <Col>
                                <label>
                                    Jobs :
                                </label>
                            </Col>
                        </Row>
                        <InputsGrid
                            definition={{
                                name: "jobs",
                                inputs: [
                                    {
                                        id: "profession",
                                        name: "Profession",
                                        type: "text",
                                        maxlength: 100,
                                    },
                                    {
                                        id: "company",
                                        name: "Company",
                                        type: "text",
                                        maxlength: 100,
                                    },
                                    {
                                        id: "location",
                                        name: "Location",
                                        type: "text",
                                        maxlength: 100,
                                    },
                                    {
                                        id: "start",
                                        name: "Start",
                                        type: "date",
                                    },
                                    {
                                        id: "end",
                                        name: "End",
                                        type: "date",
                                    },
                                    {
                                        id: "professional_experiences",
                                        type: "inputsGrid",
                                        name: "Professional experiences",
                                        inputs: [
                                            {
                                                id: "experience",
                                                name: "Professional experience",
                                                type: "text",
                                                maxlength: 100,
                                            }
                                        ],
                                        duplicate: true,
                                        delete: true,
                                    },
                                ],
                                duplicate: true,
                                delete: true,
                            }}
                            value={jobs}
                            onChange={setJobs}
                        />
                        <Row className="mt-3">
                            <Col>
                                <label>
                                    Skills :
                                </label>
                            </Col>
                        </Row>
                        <InputsGrid
                            definition={{
                                name: "skills",
                                inputs: [
                                    {
                                        id: "name",
                                        name: "Skill",
                                        type: "text",
                                        maxlength: 100,
                                    },
                                    {
                                        id: "percent",
                                        name: "Percent",
                                        type: "number",
                                        maxlength: 2,
                                    },
                                    {
                                        id: "comment",
                                        name: "Comment",
                                        type: "text",
                                        maxlength: 100,
                                    },
                                ],
                                duplicate: true,
                                delete: true,
                            }}
                            value={skills}
                            onChange={setSkills}
                        />
                        <Row className="mt-3">
                            <Col>
                                <label>
                                    Softwares :
                                </label>
                            </Col>
                        </Row>
                        <InputsGrid
                            definition={{
                                name: "softwares",
                                inputs: [
                                    {
                                        id: "name",
                                        name: "Software",
                                        type: "text",
                                        maxlength: 100,
                                    },
                                    {
                                        id: "percent",
                                        name: "Percent",
                                        type: "number",
                                        maxlength: 2,
                                    },
                                    {
                                        id: "comment",
                                        name: "Comment",
                                        type: "text",
                                        maxlength: 100,
                                    },
                                ],
                                duplicate: true,
                                delete: true,
                            }}
                            value={softwares}
                            onChange={setSoftwares}
                        />
                        <Row className="mt-3 mb-3">
                            <Col>
                                <Button
                                    type="submit"
                                    className=""
                                    onClick={handleSubmit}
                                >
                                    Enregistrer
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Footer />
            <>
                <Modal show={modalShow} onHide={() => setModalShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ whiteSpace: "pre" }}>{modalContent}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setModalShow(false)}>
                            Fermer
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        </div>
    );
}

export default AdminSettings;