import { Container, Row, Col } from 'react-bootstrap';

function Header(props) {
    let headerStyle = {
        height: props.height,
    };
    let titleStyle = { margin: "0px" };
    let subtitleStyle = { margin: "0px" };

    return (
        <Container fluid className="bgColor4" style={headerStyle}>
            <Row className="h-100">
                <Col className="my-auto text-center" xl={12}>
                    <h1 style={titleStyle}>{props.title ?? "no_title"}</h1>
                </Col>
                {
                    props.subtitle &&
                    <Col className="my-auto text-center" xl={12}>
                        <h2 style={subtitleStyle} dangerouslySetInnerHTML={{
                            __html: (props.subtitle ?? "")
                        }}>
                        </h2>
                    </Col>
                }
            </Row>
        </Container>
    );
}

export default Header;